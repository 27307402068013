import React,{useState} from 'react';
import {Link}  from 'react-router-dom';

function Home() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
    });
}
function OurStrategy() {
    const viewportHeight = window.innerHeight;
    const targetScroll = viewportHeight * 0.95; // 200vh
    window.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
    });
}
function AboutUs() {
    const viewportHeight = window.innerHeight;
    const targetScroll = viewportHeight * 2.05; // 200vh
    window.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
    });
}
function ContactUs() {
    const viewportHeight = window.innerHeight;
    const targetScroll = viewportHeight * 3.2; // 200vh
    window.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
    });
}

const Navbar=() =>{
    return(
        <div className="navbar">
            <li className="logo" >
                <img src="./images/logo.svg" alt=""/>
            </li>
            <ul className='tabbar'>
                <li onClick={Home}>
                    <Link to ="/">Home</Link>
                </li>
                <li onClick={OurStrategy}>
                    <Link to="/">Our strategy</Link>
                </li>
                <li onClick={AboutUs}>
                    <Link to="/">About us</Link>
                </li>
                <li onClick={ContactUs}>
                    <Link to="/">Contact us</Link>
                </li>
            </ul>
        </div>
    )
}

export default Navbar;