import React from "react";
import './Pages.css'
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData_I: null,
            chartData_I_GA: null,
            chartData_A: null,
            currentSlideIndex: 0,
        };
        this.chartContainerRef = React.createRef();
    }
    componentDidMount() {
        Promise.all([
            fetch('/datas/PlanI.json').then(response => response.json()),
            fetch('/datas/PlanI_GA.json').then(response => response.json()),
            fetch('/datas/PlanA.json').then(response => response.json())
        ])
        .then(([data_I, data_I_GA, data_A]) => {
            this.setState({ chartData_I: data_I, chartData_I_GA: data_I_GA, chartData_A: data_A });
        })
        .catch(error => {
            console.error('Error fetching and parsing data', error);
        });
    }
    goToPrevious = () => {
        const newIndex = Math.max(this.state.currentSlideIndex - 1, 0);
        this.setState({ currentSlideIndex: newIndex }, this.scrollChartsContainer);
    };
    goToNext = () => {
        // 假设有3个图表，通过调整下一行最后的数字调整（把1变成2）
        const newIndex = Math.min(this.state.currentSlideIndex + 1, 2);
        this.setState({ currentSlideIndex: newIndex }, this.scrollChartsContainer);
    };
    scrollChartsContainer = () => {
        const slideWidth = this.chartContainerRef.current.offsetWidth;
        this.chartContainerRef.current.scrollTo({ left: slideWidth * this.state.currentSlideIndex, behavior: 'smooth' });
    };

render() {
    const { chartData_I, chartData_I_GA, chartData_A } = this.state;
    if (!chartData_I || !chartData_I_GA || !chartData_A) {
        return <div>Loading...</div>;
    }
    const data_I = {
        labels: chartData_I.labels_I,
        datasets: [{
            label: 'NAV',
            data: chartData_I.data_I,
            fill: false,
            tension: 0.5
        }],
    };
    const data_I_GA = {
        labels: chartData_I_GA.labels_I_GA,
        datasets: [{
            label: 'NAV',
            data: chartData_I_GA.data_I_GA,
            fill: false,
            tension: 0.5
        }],
    };
    const data_A = {
        labels: chartData_A.labels_A,
        datasets: [{
            label: 'Growth',
            data: chartData_A.data_A,
            fill: false,
            tension: 0.5
        }],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        borderColor: '#00a6ff',
        pointBorderColor: '#00a6ff',
        backgroundColor: 'white',
        pointHoverRadius: 5, // 鼠标悬停时点的大小
        pointHoverBackgroundColor: 'white', // 鼠标悬停时点的背景颜色
        pointHoverBorderColor: '#00a6ff', // 鼠标悬停时点的边框颜色
        pointHoverBorderWidth: 3, // 鼠标悬停时点的边框宽度
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                    color: '#ffffff00',
                },
                ticks: {
                    font: {
                        size: 16,
                        lineHeight: 3,
                    },
                    color: 'white',
                },
            },
            y: {
                grid: {
                    color: 'white',
                    lineWidth: 0.5 ,
                },
                ticks: {
                    font: {
                        size: 16,
                        lineHeight: 3,
                    },
                    color: 'white', // y轴标签颜色
                },
                // 其他y轴配置...
            }
        },
    };
    const { CumulativeReturn_I, AverageReturn_I, SharpeRatio_I } = chartData_I;
    const { CumulativeReturn_I_GA, AverageReturn_I_GA, SharpeRatio_I_GA } = chartData_I_GA;
    const { CumulativeReturn_A, AverageReturn_A, SharpeRatio_A } = chartData_A;

return(
        <div className='content'>
            <div className="Page_Home">
                <div className='infotext'>
                    <img src="./images/logo_white.png" alt=''></img>
                    <h1>MacroArb <span className='blue'>.</span></h1>
                    <h3>- 3 years of quant trading strategy research expertise</h3>
                    <h3>- Focus on crypto and niche markets</h3>
                    <h3>- Utilization of alternative data and algorithms for high sharpe ratios</h3>
                    <br></br>
                    <div className='highlight'>
                        <h3>* MacroArb is a research group that engages in data studies out of interest, without conducting real quantitative trading.</h3>
                    </div>
                </div>
                <div className='infoimage'>
                    <img src="./images/bg_1.png" alt=''></img>
                </div>
            </div>

            <div className='Page_Strategy'>
                <div className='infotext_chart'>
                    <h1 className="title">Our strategy</h1>
                    <h3>Our expertise encompasses Cross-market arbitrage, Algorithmic Trading, Event-Driven & Emotional recognition.</h3>
                </div>

                <div className="slider">
                    <div className="slider-content charts-container" ref={this.chartContainerRef}>
                        <div className='chart'>
                            <div className='Strategy1'>
                                <div className='describe'>
                                    <div className='name'>
                                        <h2>Strategy_I : Alternative factors with Machine Learning</h2>
                                    </div>
                                    <table>
                                        <tr>
                                        <th>Cumulative return</th>
                                        <th>Average return / day</th>
                                        <th>Sharpe ratio</th>
                                        </tr>
                                        <tr>
                                        <td className='important_number'>{CumulativeReturn_I[0]}</td>
                                        <td className='important_number'>{AverageReturn_I[0]}</td>
                                        <td className='important_number'>{SharpeRatio_I[0]}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className='datachart'>
                                    <Line data={data_I} options={options} style={{padding: '20px'}}/>
                                </div>
                            </div>
                        </div>
                        <div className='chart'>
                            <div className='Strategy1'>
                                <div className='describe'>
                                    <div className='name'>
                                        <h2>Strategy_GA : Technical factors with Genetic Algorithm.</h2>
                                    </div>
                                    <table>
                                        <tr>
                                        <th>Cumulative return</th>
                                        <th>Average return / day</th>
                                        <th>Sharpe ratio</th>
                                        </tr>
                                        <tr>
                                        <td className='important_number'>{CumulativeReturn_I_GA[0]}</td>
                                        <td className='important_number'>{AverageReturn_I_GA[0]}</td>
                                        <td className='important_number'>{SharpeRatio_I_GA[0]}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className='datachart'>
                                    <Line data={data_I_GA} options={options} style={{padding: '20px'}}/>
                                </div>
                            </div>
                        </div>
                        <div className='chart'>
                            <div className='Strategy1'>
                                <div className='describe'>
                                    <div className='name'>
                                        <h2>Strategy_A(Closed): Arbitrage Strategy based on Market Price Discrepancies.</h2>
                                    </div>
                                    <table>
                                        <tr>
                                        <th>Cumulative return</th>
                                        <th>Average return / day</th>
                                        <th>Sharpe ratio</th>
                                        </tr>
                                        <tr>
                                        <td className='important_number'>{CumulativeReturn_A[0]}</td>
                                        <td className='important_number'>{AverageReturn_A[0]}</td>
                                        <td className='important_number'>{SharpeRatio_A[0]}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div className='datachart'>
                                    <Line data={data_A} options={options} style={{padding: '20px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                        <button className="slide-arrow prev-arrow" onClick={this.goToPrevious}><img src="./images/left_arrow.png" alt=''></img></button>
                        <button className="slide-arrow next-arrow" onClick={this.goToNext}><img src="./images/right_arrow.png" alt=''></img></button>
                </div>
            </div>

            <div className="Page_About">
                <div className="infotext">
                    <h1 className="title">About us</h1>
                </div>
                <div className="aboutus">
                    <div className="intromodal">
                        <img src="./images/cy.png" alt=''></img>
                        <h2>Chen&nbsp;Yue&nbsp;-&nbsp;CEO</h2>
                        <div className="introduction">
                            <h3>ChenYue has served as a product director in multiple tech companies, excelling in market analysis and strategic decision-making, with a deep understanding of trading and arbitrage strategies.</h3>
                            <h3>Yue has an MBA degree from Tsinghua University × MIT.</h3>
                        </div>
                    </div>
                    <div className="intromodal">
                        <img src="./images/qyy.png" alt=''></img>
                        <h2>Qian&nbsp;Yuyang&nbsp;-&nbsp;CTO</h2>
                        <div className="introduction">
                            <h3>Qian&nbsp;Yuyang is well-versed in various trading platforms and market-making principles, has extensive expertise in engineering and algorithms, and possesses unique insights into program trading strategies.</h3>
                            <h3>Qian has a Ph.D. degree in EE from Tsinghua University.</h3>
                        </div>
                    </div>
                    <div className="intromodal">
                        <img src="./images/kjg.png" alt=''></img>
                        <h2>Kong&nbsp;Jiangang&nbsp;-&nbsp;SDE</h2>
                        <div className="introduction">
                            <h3>Kong&nbsp;Jiangang, formerly provided backend programming services for Huawei OS, with a deep understanding of data and algorithms.</h3>
                            <h3>Kong has a master degree in Software Engineering from Nanjing University.</h3>
                        </div>
                    </div>
                    <div className="intromodal">
                        <img src="./images/dxn.png" alt=''></img>
                        <h2>Ding&nbsp;Xuenan&nbsp;-&nbsp;Strategy</h2>
                        <div className="introduction">
                            <h3>Ding&nbsp;Xuenan has a very keen sense of market and strategy, and possesses unique insights into the application and innovation of new tools and methodologies.</h3>
                            <h3>Ding has a master degree from Tsinghua University.</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Pages_Contact">
                <div className="infotext">
                    <h1 className="title">Contact us</h1>
                    <br></br>
                    <br></br>
                    <h3>We are committed to preserving client assets while generating excess returns for our clients.</h3>
                    <h3>Please contact: <a href="mailto:service@macroarb.capital">service@macroarb.capital</a></h3>
                    <br></br>
                    <h3>We are a team committed to continually creating value in the niche market.Looking forward for you to join us and share profits. </h3>
                    <h3>Please contact: <a href="mailto:hr@macroarb.capital">hr@macroarb.capital</a></h3>
                </div>
                <div className='infoimage'>
                    <img src="./images/contact.png" alt=''></img>
                </div>
            </div>

        <div className="footer">
            <div className="address">
                <h2>Address</h2>
                <h3>FLAT 2304, 23/F HO KING, COMMERCIAL CENTRE, 2-16 FA YUEN STREET, MONG KOK KOWLOON HONG KONG</h3>
            </div>
            <ul>
                <li><a href="./docs/Terms_MacroArb.pdf">Privacy Policy</a></li>
                <li><a href="./docs/Terms_MacroArb.pdf">Terms Of Use</a></li>
                <li><a href="./docs/Terms_MacroArb.pdf">Disclosures</a></li>
            </ul>
            <div className="finally">
            <h3>MacroArb 2024 © with passion. All rights reserved. Enjoy Responsibily.</h3>
            </div>
        </div>
    </div>
    )
}
}

export default Home;

