import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/navbar'
import Home from './pages/Home.js';

function App() {
  return (
    <div >
   <Router>
   <Navbar></Navbar>
   <Switch>
    <Route path="/" exact component={Home}/>
   </Switch>
   
   </Router>
    </div>
  );
}

export default App;
